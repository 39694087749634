/* eslint-disable */
import React, { PureComponent } from 'react';

import { Helmet } from 'react-helmet';

import seo from 'tools/seo';

import Loading from 'components/Loading';

type Props = {};

class Prices extends PureComponent<Props> {
  state = { loaded: false };

  onLoad = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    // https://magic.reactjs.net/htmltojsx.htm
    return (
      <>
        <Helmet>
          {seo.title('Prices and commissions - Flowfo')}
          {seo.description(
            'Most of our content is free. All prices are defined by the creator. We take a commission only if paying transactions are made.',
          )}
        </Helmet>
        {!loaded && <Loading />}
        <div className={`Page-Webflow ${loaded || 'd-none'}`}>
          {/* START HTML WEBFLOW PRICES ENGLISH */}

          {/* This site was created in Webflow. http://www.webflow.com */}
          {/* Last Published: Thu Sep 02 2021 09:24:11 GMT+0000 (Coordinated Universal Time) */}
          <meta charSet="utf-8" />
          <title>ENGLISH - Prices</title>
          <meta content="ENGLISH - Prices" property="og:title" />
          <meta content="ENGLISH - Prices" property="twitter:title" />
          <meta content="Webflow" name="generator" />
          <link
            href="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/css/prices-flowfo.webflow.1b0d16f7d.css"
            onLoad={this.onLoad}
            rel="stylesheet"
            type="text/css"
          />
          {/*[if lt IE 9]><![endif]*/}
          <link href="https://uploads-ssl.webflow.com/img/favicon.ico" rel="shortcut icon" type="image/x-icon" />
          <link href="https://uploads-ssl.webflow.com/img/webclip.png" rel="apple-touch-icon" />
          <div className="wf-section">
            <div className="divdeslangages">
              <a href="https://flowfo.me/fr/prices" className="linklanguage w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/5d285eb314c23d4cf7cb9f29/5f95ec5bf00d8b10e6b4166c_THREE%20FLAGS.png"
                  alt="Three flags"
                  className="drapeauxronds"
                />
                <p className="changelangue">
                  lire en français
                  <br />
                </p>
              </a>
            </div>
            <div className="divdesprix">
              <div className="block-prices">
                <h1 className="webflowheading1">Prices</h1>
                <h2 className="webflowheading2">Only in Euro</h2>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone">
                      <img
                        src="https://uploads-ssl.webflow.com/5d26ffc41443e0eefacca149/5d26ffc41443e08c05cca17a_euro%20circle.svg"
                        alt=""
                        className="image-8"
                      />
                    </div>
                  </div>
                  <div className="text-zone-of-cartouche">
                    <h3 className="webflowheading3 red">Creator's decision</h3>
                    <p className="webflowparagraphe">
                      All prices are defined by the creator who owns the Flow.
                      <br />
                      Flowfo takes a commission when a paid transaction is made.
                      <br />
                      Creators are paid each month, based on sales made on their Flows.
                      <br />
                    </p>
                  </div>
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone">
                      <img
                        src="https://uploads-ssl.webflow.com/5d26ffc41443e0eefacca149/5d26ffc41443e06574cca17c_vat%20circle.svg"
                        alt=""
                        className="image-8"
                      />
                    </div>
                  </div>
                  <div className="text-zone-of-cartouche">
                    <h3 className="webflowheading3 violet">We deal with the VAT</h3>
                    <p className="webflowparagraphe">
                      We define the right VAT and automatically add it to the price. <br />
                    </p>
                  </div>
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche-2">
                  <div>
                    <div className="icone-zone">
                      <img
                        src="https://uploads-ssl.webflow.com/5f81a7a240627d7c821c69f2/5f81a7a240627d594f1c6a0b_conseil.svg"
                        alt=""
                        className="image-8"
                      />
                    </div>
                  </div>
                  <div className="text-zone-of-cartouche">
                    <p className="webflowparagraphe">
                      <strong>
                        No minimum or hidden fees. We only charge the commission if the creator makes sales.
                        <br />A free Flow costs nothing.
                      </strong>
                      <br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="block-commission">
                <h2 className="webflowheading1">Commission</h2>
                <p className="webflowheading2">
                  We take
                  <br />
                </p>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <div className="cartouche">
                  <div>
                    <div className="icone-zone" />
                  </div>
                  <div className="text-zone-of-cartouche" />
                </div>
                <h3 className="webflowheading1 violet">10,9% + 0,30€</h3>
                <h2 className="webflowheading2 violet">per transaction</h2>
                <h3 className="webflowheading3">Pay in included</h3>
                <p className="webflowparagraphe centeredtext">
                  Pay in fees are
                  <br />
                  included in our commission.
                  <br />
                  <strong>Payout is an additional charge</strong>, <br />
                  depending of your chosen provider (Paypal or SEPA)
                  <br />
                  and the currency you need.
                  <br />
                </p>
              </div>
            </div>
            <div className="divwithcontent">
              <div className="w-container">
                <h3 className="webflowheading3">Free, paying or subscription Flow?</h3>
                <p className="webflowparagraphe">
                  <strong>A FREE FLOW</strong> is a folder whose content is accessible to all (visitors included).
                  <br />A free Flow can become a paying one (and vice versa).
                  <br />
                  <br />
                  <strong>A PAYING FLOW</strong> is a folder whose content is accessible only to users who have
                  purchased an access right. <br />
                  This access remains valid throughout the life of the Flow.
                  <br />
                  <br />
                  <strong>A SUBSCRIPTION FLOW</strong> is a file whose content is accessible only to Flow subscribers
                  (monthly renewal).
                  <br />
                </p>
                <h3 className="webflowheading3">About subscriptions</h3>
                <p className="webflowparagraphe">
                  Subscriptions to Flows are cumulative: if a user subscribes to several Flows, he pays Flow A + Flow B
                  + Flow C ... <br />
                  The subscription renewal is automatic, on a monthly basis which always starts on the 1st of the month
                  (there is no sliding period).
                  <br />
                  As access to Flow is instantaneous, the 1st installment is always paid immediately and is not subject
                  to any discount (even if the end of the month is approaching).
                  <br />A subscriber can terminate his subscription at any time. Access to Flow remains until the end of
                  the current month.
                  <strong>
                    <br />
                  </strong>
                </p>
                <h3 className="webflowheading3 red">About the prices</h3>
                <p className="webflowparagraphe">
                  The prices for the Flows have a <strong>minimum of 2€ and a maximum of 50€.</strong>
                  <br />
                  <strong />
                </p>
                <h3 className="webflowheading3 red">Who pays the VAT?</h3>
                <p className="webflowparagraphe">
                  The VAT is paid by the user. <br />
                  It's calculated according to their residence country and is added to the final price.
                  <br />
                  Flowfo reverses the VAT to the administrations.
                </p>
                <h3 className="webflowheading3 violet">Payout</h3>
                <p className="webflowparagraphe">
                  Payouts are made <strong>the 10th of each month.</strong> The currency is <strong>Euro €.</strong>
                  <br />
                  The payout method is chosen by the Creator.
                  <br />
                  <br />
                  <strong>SEPA TRANSFER</strong> - for European creators having a bank account.
                  <br />
                  <strong>PAYPAL</strong> - We have to pay Creators via <em>commercial transaction </em>which generates
                  fees. <em>Friends and family option</em> is not possible. For more details about Paypal fees, visit{' '}
                  <a href="http://www.paypal.com" target="_blank" className="webflowlink">
                    www.paypal.com
                  </a>
                  ‍
                </p>
                <h3 className="webflowheading3 violet">What is a transaction?</h3>
                <p className="webflowparagraphe">
                  A transaction is defined as a sale, new or renewed subscription.
                  <br />
                  If a creator sells two paying Flows + one subscription Flowduring an order, it means three
                  transactions.
                  <br />
                  <strong>There is no commission with free Flows.</strong>
                </p>
                <h3 className="webflowheading3 violet">Does VAT affect the commission?</h3>
                <p className="webflowparagraphe">
                  No. We assume any extra costs due to the VAT amount.
                  <br />
                  The commission is only based on the price defined by the creator.
                  <br />
                  <strong>
                    As a creator, if you define your price to 4€, you'll be paid 4€ - 10,9% - 0,30€ = 3,26€
                  </strong>
                </p>
                <h3 className="webflowheading3 red">Are my CB numbers safe?</h3>
                <p className="webflowparagraphe">
                  We do not host your CB numbers on our servers. <br />
                  They are directly sent via secured link to our payment provider (which respects the PCI standard).
                  <strong />
                </p>
                <div className="divendblank" />
              </div>
            </div>
          </div>
          {/*[if lte IE 9]><![endif]*/}

          {/* END HTML WEBFLOW PRICES ENGLISH */}
        </div>
      </>
    );
  }
}

export default Prices;
